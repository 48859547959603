
/*------------------
Services section
-------------------*/

.service {
    padding: 30px;
    position: relative;
    transition: 0.3s;
    border: 1px solid #EEF0F6;
    z-index: 1
}

.service.box-1 {
    background-color: #ffffff;
    position: relative;
}

.service.box-1 .line {
    position: absolute;
    width: 0;
    height: 0;
    display: block;
    opacity: 0;
    transition: 0.3s;
}

.service.box-1:hover .line {
    width: 30px;
    height: 30px;
    opacity: 1;
}

.service.box-1 .line:nth-child(1) {
    left: -1px;
    top: -1px;
    border-left: 1px solid $primary;
    border-top: 1px solid $primary;
}

.service.box-1 .line:nth-child(2) {
    right: -1px;
    top: -1px;
    border-right: 1px solid $primary;
    border-top: 1px solid $primary;
}

.service.box-1 .line:nth-child(3) {
    right: -1px;
    bottom: -1px;
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;
}

.service.box-1 .line:nth-child(4) {
    left: -1px;
    bottom: -1px;
    border-left: 1px solid $primary;
    border-bottom: 1px solid $primary;
}

.service:hover {
    background-color: #ffffff;
}


.service.two {
    background-color: #ffffff;
    transform: translateY(0);
    transition: 0.3s;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.0)
}

.service.two:hover {
    background-color: #F7F8FB;
    transform: translateY(-5px);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2)
}

.service .service-icon {
    width: 80px;
    height: 30px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        width: 100%;
    }

    .fas, .fab {
        color: $primary
    }
}

.service .title {
    font-size: 20px;
    margin-bottom: 15px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.icon-title {
    color: #729DFE;
    overflow: hidden;
    line-height: 40px;
    font-size: 16px;
}

.icon-title .icon {
    float: left;
    color: #729DFE;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    background-color: #E2EAFB;
    border-radius: 3px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
}


// More button

// .bttn-more {
//     color: $primary;
//     display: inline-block;
//     margin-bottom: 0;
//     margin-top: 5px;

//     &:hover {
//         color: $primary;
//         text-decoration: none
//     }
// }

// .bttn-more.ppl {
//     color: #95B6FE;
// }

// .bttn-more:before {
//     content: "";
//     width: 20px;
//     height: 2px;
//     margin-right: 5px;
//     border-radius: 10px;
//     -webkit-transform: translateY(-4px);
//     transform: translateY(-4px);
//     background-color: #28CC8B;
//     display: inline-block;
//     -webkit-transition: 0.3s;
//     transition: 0.3s;
// }

// .bttn-more.ppl:before {
//     background-color: #95B6FE;
// }

// .bttn-more:hover:before {
//     width: 5px;
// }

// .bttn-more:after {
//     content: "\f0da";
//     font-family: "Font Awesome 5 Free";
//     font-weight: 900;
//     line-height: 16px;
//     font-size: 16px;
//     margin-left: 0;
//     opacity: 0;
//     -webkit-transform: translateY(1px);
//     transform: translateY(1px);
//     display: inline-block;
//     -webkit-transition: 0.3s;
//     transition: 0.3s;
// }

// .bttn-more:hover:after {
//     margin-left: 10px;
//     opacity: 1;
// }

