$primary: #0fe0ba;
$primary-dark: #00ffd1;

@import "~node_modules/bootstrap/scss/functions";
@import "~node_modules/bootstrap/scss/variables";
@import "~node_modules/bootstrap/scss/mixins";

@import "~node_modules/bootstrap/scss/reboot";
// @import "~node_modules/bootstrap/scss/root";
@import "~node_modules/bootstrap/scss/grid";
@import "~node_modules/bootstrap/scss/type";

@import "~node_modules/bootstrap/scss/nav";
@import "~node_modules/bootstrap/scss/navbar";

@import "~node_modules/bootstrap/scss/utilities/spacing";
@import "~node_modules/bootstrap/scss/utilities/text";
@import "~node_modules/bootstrap/scss/utilities/flex";

@import "~node_modules/owl.carousel/src/scss/owl.carousel.scss";

// #logo {
//   position: absolute;
//   border-radius: 100%;
//   top:50%;
//   z-index: 2;
//   // transform: translate(50%, 50%);
//   transition: position 1s ease-in;
// }
.navbar-collapse {
  letter-spacing: 1px;
}
html,
body {
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
.heading {
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Montserrat", "Helvetica Neue", "Lucida Grande", Arial, Verdana,
    sans-serif;
}
.montserrat {
  font-family: "Montserrat";
}
#loading {
  width: 100vw;
  height: 100vh;
  background: #20232d;
  position: fixed;
  z-index: 999;
}

#preloader {
  position: relative;
  width: 100%;
  height: 80px;
  top: calc(50% - 50px);
  text-align: center;
  margin: 0 auto;

  &:after {
    content: "Innovative Solutions in Tech & Cloud Apps";
    position: absolute;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
    font-family: Montserrat, "Helvetica Neue", "Lucida Grande", Arial, Verdana,
      sans-serif;
    top: 90px;
    width: 100%;
    left: 0;
    right: 0;
    height: 1px;
    text-align: center;
  }

  span {
    position: absolute;
    border: 4px solid $primary;
    border-radius: 100%;
  }

  span:nth-child(1) {
    width: 80px;
    height: 80px;
    left: calc(50% - 40px);
    animation: spin-1 1s infinite cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  span:nth-child(2) {
    top: 20px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    animation: spin-2 1s infinite cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.navbar {
  z-index: 1;
  text-align: center;

  // a {
  //   color: $primary;
  // }

  .navbar-nav .nav-item {
    color: #fff;
    &:hover {
      color: $primary-dark;
    }
  }
  // .fas {
  //   color: #fff;
  //   font-size: 10px;
  //   padding-top: 18px;
  // }
}

.heading + hr {
  height: 0.4rem;
  border: 0;
  background: #000;
  width: 6rem;
  border-radius: 10px;
}

/*------------------
Landing 
-------------------*/

#home-wrapper {
  // position: absolute;
  background-image: url("./img/constel.jpg");
  background-image: url("./img/constel.webp");
  background-size: cover;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 0;
  // transform-style: preserve-3d;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);

  .text-intro {
    opacity: 0;
  }

  .content {
    z-index: 2;
    position: relative;
    top: 20%;
    text-align: center;
  }

  img {
    position: relative;
    max-width: 200px;
    margin: auto;
    margin-bottom: 2rem;
    border-radius: 100%;
  }

  h1 {
    font-size: 54px;
    font-weight: 400;
    color: #fff;
    text-transform: none;
    font-family: Pacifico, "Helvetica Neue", "Lucida Grande", Arial, Verdana,
      sans-serif;
  }

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    background: rgba(32, 35, 45, 0.8);
    opacity: 0.4;
    z-index: 0;
    outline: none;
  }
}

.social-icons {
  position: absolute;
  bottom: 2%;
  display: inline-block;
  text-align: center;
  width: 100%;
  z-index: 9;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.7s cubic-bezier(0.42, 0, 0.58, 1);

  a {
    color: #fff;
    width: 40px;
    line-height: 40px;
    background: transparent;
    font-size: 1em;
    height: 40px;
    display: inline-block;
    text-align: center;
    margin: 0 2px;
    border: none;
    transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);

    &:hover i {
      color: $primary;
      transform: scale(1.2);
    }
  }

  i {
    transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
  }
}

.overlay {
  background: url(./img/overlay.svg);
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.4;
}

#particles-js {
  z-index: 1;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 1;
}

/*------------------
Clients section
-------------------*/

#clients-wrapper {
  background: #fff;
  position: relative;
  max-height: 500px;
}

#clients-wrapper h3 {
  color: #000;
}

#clients-wrapper img {
  width: 150px;
  margin: auto;
}

.owl-carousel {
  max-width: 900px;
  width: 100%;
  margin: 3rem auto;
}

.owl-carousel .fa {
  font-size: 1.3rem;
  color: black;
}

.owl-nav {
  margin-top: 2rem;
}

.owl-item {
  height: 200px;
}

.owl-item > a {
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%);
}

#footer-wrapper {
  position: relative;
  background-color: #20232d;
  display: block;
  text-align: center;
  color: #d3d4d4;

  & > div {
    padding: 5% 10%;
  }

  a {
    color: #d3d4d4;
  }

  .heading {
    color: #f7f7f7;
  }

  i {
    color: $primary;
    font-size: 2rem;
  }

  &:before {
    z-index: 5;
    position: absolute;
    left: calc(50% - 50px);
    top: -50px;
    content: "";
    width: 0;
    height: 0;
    border-bottom: solid 50px #20232d;
    border-left: solid 50px transparent;
    border-right: solid 50px transparent;
  }
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 4rem;

  h5 {
    margin-top: 15px;
  }

  img {
    width: 150px;
    height: 100%;
    border-radius: 50%;
    border: 2px dotted;
  }
  p {
    margin: 0;
  }
}
.team-member {
  padding: 20px;
  border-radius: 10px;
  transition: box-shadow .3s ease-out;
  border: 2px solid transparent;

  color:#212529;

  &:hover {
    text-decoration: none;
    color:#212529;

    box-shadow: 3px 3px 20px #b7fff2;
    // border: 2px solid $primary;
  }
}

/* purgecss ignore */
.fadeInUp {
  animation: fadeInUp 1s both;
}
.focus-in-expand-fwd {
  animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 2.5s;
}

@media (max-width: 992px) {
  .navbar-collapse {
    display: none;
    overflow: hidden;
  }

  .team-member {
    margin: 1rem 2rem;
  }
}

@media (max-width: 576px) {
  #home-wrapper h1 {
    font-size: 42px;
  }
  .team-container {
    flex-direction: column;
  }
}

@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes spin-1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin-2 {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


@import "./styles/services.scss";
